<template>
  <v-container class="bg-color-theme">
    <v-row>
      <v-col cols="12" md="12">
        <v-card elevation="2" class="mx-auto py-2 px-4" max-width="344">
          <v-card-title class="align-baseline px-0 py-0">
            <v-icon class="circle-checked mt-n1" size="15" color="#000"
              >$circleChecked</v-icon
            >
            <div class="ml-2">
              <h6 class="fw-500 font-16px mb-0 text-333">
                {{ getSupplier.display_name }}
              </h6>
              <v-chip
                v-for="category in getCategories()"
                :key="category.id"
                color="primary"
                outlined
                small
                class="mr-2 cat-chip-height"
              >
                {{ getCategoryName(category) }}
              </v-chip>
              <div
                class="font-14px text-67"
                v-if="getSupplier.telephone_number"
              >
                <v-icon small class="mt-n1">
                  $phone
                </v-icon>

                <span class="ml-1 fw-400">
                  {{ getSupplier.telephone_number }}
                </span>
              </div>
            </div>
            <v-btn icon @click="expand = !expand" top="3" class="ml-auto">
              <v-icon>{{
                expand ? "mdi-chevron-up" : "mdi-chevron-down"
              }}</v-icon>
            </v-btn>
          </v-card-title>

          <v-expand-transition>
            <div v-show="expand" class="mt-2">
              <div v-for="field in fields" :key="field.name">
                <p class="font-12px text-a9 mb-0">{{ field.label }}</p>
                <p class="font-14px pb-2 pt-1 supp-field-border-bottom">
                  {{ field.text }}
                </p>
              </div>
            </div>
          </v-expand-transition>
          <div class="text-right">
            <router-link
              :to="{ name: 'SupplierEdit', params: { id: $route.params.id } }"
              class="d-inline-block letter-spacing-unset text-decoration-none mb-4 fw-400 font-14px text-333"
            >
              <v-icon small class="mr-2 icon-opacity">
                $edit
              </v-icon>
              取引先情報を編集する
            </router-link>
          </div>

          <v-tabs
            v-model="tab"
            grow
            height="30px"
            class="font-12px"
            active-class="tab-active"
            hide-slider
            background-color="#E5E5E5"
            color="#898989
  "
          >
            <v-tab class="font-12px tabs">
              買掛
            </v-tab>
            <v-tab class="font-12px tabs">
              現金出納
            </v-tab>
          </v-tabs>

          <v-tabs-items v-model="tab">
            <v-tab-item>
              <p class="font-12px mt-4">
                <span class="font-weight-medium" v-if="getAccountPayPaginate">
                  {{
                    `${getAccountPayPaginate.records_from}~${getAccountPayPaginate.records_to}件 / ${getAccountPayPaginate.records_total}件`
                  }}
                </span>
                <span class="text--disabled">
                  の買掛データを表示しています。
                </span>
              </p>

              <DataTable
                :headers="table1Headers"
                :items="loading ? [] : getAccountsPayables"
                :total-records="
                  getAccountPayPaginate
                    ? getAccountPayPaginate.records_total
                    : 0
                "
                :number-of-pages="
                  getAccountPayPaginate ? getAccountPayPaginate.total_pages : 0
                "
                :items-per-page="tableItemPerPage"
                class="supplier-data-tables"
                mobile-breakpoint="275"
                @update:options="updateTable($event, 'accounts')"
              >
                <template v-slot:[`item.accrual_date`]="{ item }">
                  {{ item.accrual_date | formatDate("MM/DD dd") }}
                </template>
                <template v-slot:item.purchase_amount="{ item }">
                  {{ item.purchase_amount | formatMoney }}
                </template>
                <template v-slot:item.notes="{ item }">
                  <span class="truncate-lines lines-1">
                    {{ item.notes }}
                  </span>
                </template>
                <template v-slot:[`item.actions`]="{ item }">
                  <v-icon
                    small
                    class="mr-2 icon-opacity"
                    role="button"
                    @click.stop="
                      $router.push({
                        name: 'accountsPayableEdit',
                        params: { id: item.id }
                      })
                    "
                  >
                    $edit
                  </v-icon>
                </template>
              </DataTable>
            </v-tab-item>
            <v-tab-item>
              <p class="font-12px mt-4" v-if="getCashbookPaginate">
                <span class="font-weight-medium">
                  {{
                    `${getCashbookPaginate.records_from}~${getCashbookPaginate.records_to}件 / ${getCashbookPaginate.records_total}件`
                  }}
                </span>
                <span class="text--disabled">
                  の買掛データを表示しています。
                </span>
              </p>
              <DataTable
                :headers="table2Headers"
                :items="loading ? [] : getCashbooks"
                :total-records="
                  getCashbookPaginate ? getCashbookPaginate.records_total : 0
                "
                :number-of-pages="
                  getCashbookPaginate ? getCashbookPaginate.total_pages : 0
                "
                :items-per-page="tableItemPerPage"
                :item-class="`col-bg-color`"
                class="supplier-data-tables"
                mobile-breakpoint="275"
                @update:options="updateTable($event, 'cashflow')"
              >
                <template v-slot:[`item.accrual_date`]="{ item }">
                  {{ item.accrual_date | formatDate("MM/DD dd") }}
                </template>
                <template v-slot:[`item.deposit`]="{ item }">
                  <span class="fw-600" v-if="item.withdrawal_or_deposit === 2">
                    {{ item.purchase_amount | formatNumber }}
                  </span>
                  <span v-else>
                    {{ item.partner.display_name }}
                  </span>
                </template>
                <template v-slot:[`item.withdrawal`]="{ item }">
                  <span
                    class="fw-600 dark-red--text"
                    v-if="item.withdrawal_or_deposit === 1"
                  >
                    {{ item.purchase_amount | formatNumber }}
                  </span>
                </template>
                <template v-slot:[`item.actions`]="{ item }">
                  <v-icon
                    small
                    class="mr-2 icon-opacity"
                    role="button"
                    @click.stop="
                      $router.push({
                        name: 'cashbookEdit',
                        params: { id: item.id }
                      })
                    "
                  >
                    $edit
                  </v-icon>
                </template>
              </DataTable>
            </v-tab-item>
          </v-tabs-items>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import DataTable from "@/components/ui/DataTable";
import { mapGetters } from "vuex";
export default {
  name: "SupplierDetails",
  components: {
    DataTable
  },
  data() {
    return {
      model: 0,
      loading: false,
      initialLoad: true,
      expand: false,
      tab: 0,
      table1Headers: [
        {
          text: "取引日",
          value: "accrual_date",
          align: "center",
          sortable: true
        },
        {
          text: "適用 / メモ",
          value: "notes",
          align: "center",
          width: "30%"
        },
        { text: "仕入額", value: "purchase_amount", align: "center" },
        { text: "", value: "actions", align: "center" }
      ],
      table2Headers: [
        {
          text: "取引日",
          value: "accrual_date",
          align: "center",
          sortable: true
        },
        {
          text: "入金",
          value: "deposit",
          align: "center"
        },
        { text: "出金", value: "withdrawal", align: "center" },
        { text: "", value: "actions", align: "center" }
      ],
      fields: [
        {
          name: "business_partner_name",
          label: "取引先名称",
          text: "株式会社肉のマルタヤ"
        },
        {
          name: "address",
          label: "住所",
          text: "東京都荒川区荒川1-1-1"
        },
        {
          name: "home_page",
          label: "ホームページ",
          text: "http://marutaya.com"
        },
        {
          name: "contact_person",
          label: "担当者名",
          text: "丸田丸男"
        },
        {
          name: "note",
          label: "メモ",
          text: "牛肉専用、丸田さん以外に注文すると割引がきかないので要注意"
        }
      ]
    };
  },
  created() {
    this.getDataFromApi();
    this.getCashBookApiData();
    this.getAccountPayableApiData();
  },
  computed: {
    ...mapGetters([
      "getSupplier",
      "getAccountsPayables",
      "getCashbooks",
      "getAccountPayPaginate",
      "getCashbookPaginate",
      "getMasterData"
    ]),
    currentShop() {
      return this.$store.getters.getFirstShopObject;
    }
  },
  methods: {
    getDataFromApi() {
      this.loading = true;
      this.$store
        .dispatch("SUPPLIER_GET", {
          id: this.$route.params.id
        })
        .finally(() => {
          this.loading = false;
          this.initialLoad = false;
          this.fillFields();
        });
    },

    updateTable(e, tableFor = "accounts") {
      if (!this.initialLoad && tableFor == "accounts") {
        this.getAccountPayableApiData(e);
      } else if (!this.initialLoad && tableFor == "cashflow") {
        this.getCashBookApiData(e);
      }
    },
    getAccountPayableApiData(e = undefined) {
      this.loading = true;
      let params = {
        partner_id: this.$route.params.id,
        page: 1,
        paginate: this.tableItemPerPage // mixins var
      };

      if (e?.sortBy !== undefined && e?.sortDesc !== undefined) {
        params.orderBy = e.sortDesc[0] ? "desc" : "asc";
        params.sort = e.sortBy[0];
      } else {
        params.sort = "accrual_date";
        params.orderBy = "desc";
      }

      if (e?.page !== undefined && e?.itemsPerPage !== undefined) {
        params.page = e.page;
        params.paginate = e.itemsPerPage;
      }
      this.$store
        .dispatch("ACCOUNTS_PAYABLE_GET_ALL", {
          id: this.currentShop.id,
          param: params
        })
        .finally(() => {
          this.loading = false;
          this.initialLoad = false;
          this.fillFields();
        });
    },
    getCashBookApiData(e = undefined) {
      this.loading = true;
      let params = {
        partner_id: this.$route.params.id,
        page: 1,
        paginate: 15
      };

      if (e?.sortBy !== undefined && e?.sortDesc !== undefined) {
        params.orderBy = e.sortDesc[0] ? "desc" : "asc";
        params.sort = e.sortBy[0];
      } else {
        params.sort = "accrual_date";
        params.orderBy = "desc";
      }

      if (e?.page !== undefined && e?.itemsPerPage !== undefined) {
        params.page = e.page;
        params.paginate = e.itemsPerPage;
      }

      this.$store
        .dispatch("CASHBOOK_GET_ALL", {
          id: this.currentShop.id,
          param: params
        })
        .finally(() => {
          this.loading = false;
          this.initialLoad = false;
          this.fillFields();
        });
    },
    fillFields() {
      this.fields.forEach(field => {
        field.text = this.getSupplier?.[field.name];
      });
    },
    getCategories() {
      if (!this.getSupplier?.category) {
        return;
      }
      return this.getSupplier.category;
    },
    getCategoryName(category) {
      return (
        this.getMasterData.partners_category.find(cat => cat.id === category)
          ?.value || ""
      );
    }
  }
};
</script>

<style lang="scss" scope>
@import "@/styles/_variables.scss";

.tab-active {
  border-top: 3px solid $primary;
  color: $primary !important;
  background-color: white !important;
}
.v-tabs-bar {
  border-radius: 6px 6px 0px 0px !important;
}
.v-tab.tabs {
  width: 50%;
}
.col-bg-color {
  td:nth-child(2) {
    background-color: #ebffee;
  }
}
.supplier-data-tables {
  tr {
    td,
    th {
      padding: 0 10px !important;
      height: 32px !important;
    }
  }
}

.pagination-main-outer {
  .v-pagination__item {
    border-radius: 4px;
  }
}
.cat-chip-height {
  height: 20px;
}
.letter-spacing-unset {
  letter-spacing: unset;
}
.supp-field-border-bottom {
  border-bottom: thin solid #a9a9a9;
}
</style>
